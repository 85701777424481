import React from 'react'
import ContactForm from '../components/ContactForm/ContactForm'
import SEO from '../components/SEO/SEO'
export default function ContactPage() {
  return (
    <>
      <SEO title="Contact" route="/contact"></SEO>
      <ContactForm></ContactForm>
    </>
  )
}
